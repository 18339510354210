<script setup lang="ts">
import { ViewBaseButton } from '~/components/atoms/ABaseButton';

const screenWidth = ref(0);

const updateScreenWidth = () => {
  screenWidth.value = window.innerWidth - 40;
};

const auth = useAuth();

onBeforeMount(async () => {
  const isLoggedIn = await auth.optimisticAuth();
  if (isLoggedIn) {
    await navigateTo('/home')
  }
})

if (import.meta.client) {
  onMounted(() => {
    updateScreenWidth();
    window.addEventListener('resize', updateScreenWidth);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateScreenWidth);
  });
}

const list = [
  {
    img: '/images/slide-1.webp',
    text: 'Пополняйте счёт<br/>криптовалютой и оплачивайте покупки через СБП',
  },
  {
    img: '/images/slide-2.webp',
    text: 'Браузер c мини-приложениями под любые задачи',
  },
  {
    img: '/images/slide-3.webp',
    text: 'Покупайте криптовалюту<br/>без рисков для себя',
  },
];

definePageMeta({
  middleware: ['public-only'],
  noPWA: true,
  layout: 'auth',
});
</script>

<template>
  <TMain class="p-hero" :header-logo="true" :back="false">
    <template #body>
      <div class="carousel-wrapper" :style="{ maxWidth: `${screenWidth}px` }">
        <Carousel
          :value="list"
          :num-visible="1"
          :num-scroll="1"
          :autoplay-interval="2500"
          class="custom-carousel"
        >
          <template #item="slotProps">
            <div class="carousel-item" :style="{ maxWidth: `${screenWidth}px` }">
              <img :src="slotProps.data.img" alt="Antarctic Wallet" />
              <!-- eslint-disable-next-line vue/no-v-html -->
              <h2 class="title" v-html="slotProps.data.text" />
            </div>
          </template>
        </Carousel>
      </div>
    </template>
    <template #action>
      <ABaseButton block to="/auth/register">Зарегистрироваться</ABaseButton>
      <ABaseButton block :view="ViewBaseButton.Tertiary" to="/auth/login">
        У меня уже есть аккаунт
      </ABaseButton>
    </template>
  </TMain>
</template>

<style lang="scss">
.p-hero {
  text-align: center;
  .page-body {
    margin: auto;
  }
  .title {
    font-size: 22px;
    line-height: 26px;
    margin: 13px 0 0;
  }
  .carousel-wrapper {
    overflow: hidden;
  }
  .carousel-item img {
    max-width: 300px;
  }
  .p-button {
    display: none;
  }
  .p-carousel-indicator-list {
    padding: 32px 0 0;
  }
  .p-carousel-indicator .p-carousel-indicator-button {
    width: 10px;
    height: 10px;
    transition: 0.25s ease-in-out;
  }
  .p-carousel-indicator.p-carousel-indicator-active .p-carousel-indicator-button {
    padding: 0 18px;
    background-color: $bg-accent;
  }
  .a-base-button {
    margin-top: 10px;
  }
}
</style>
